import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
export default function Secone(){
    return (
        <>
        <Container fluid className="__bg-primary">
            <Container>
                <Row className="fade-in">
                    <Col lg={12}>
                        <div className="_logo-lc">
                            <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/Ladingpage-plc/Logo-Lovecoach.png" alt="logo lovecoach"/>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="_logo-commitment-mastery">
                            <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/Ladingpage-plc/Logo-CM.png" alt="logo cm"/>
                        </div>
                    </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

